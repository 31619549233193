/* You can add global styles to this file, and also import other style files */
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
@import 'assets/potre/build/potree/heli.css';
@import 'assets/potre/libs/openlayers3/ol.css';
@import 'assets/potre/libs/jquery-ui/jquery-ui.min.css';
@import 'assets/potre/libs/spectrum/spectrum.css';
@import 'assets/potre/libs/jstree/themes/mixed/style.css';
.custom-dialog .mat-dialog-container {
  padding: 0px !important; // Set padding to 0px
}

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  // overflow: hidden;
}
.spacer{
  flex: 1 1 auto;
}
a{
  text-decoration: none;
}
mat-toolbar a{
  margin: 0 10px;
  display: inline-block;
  text-decoration: none;
  color: white;
  font-size: 16px;
}
.Red{
  color: #f44336;
}
.Pink{
  color: #ec407a;
}
.Purple{
  color: #ab47bc;
}
.DeepPurple{
  color: #7e57c2;
}
.Indigo{
  color: #5c6bc0;
}
.Blue{
  color: #42a5f5;
}
.LightBlue{
  color: #29b6f6;
}
.Cyan{
  color: #26c6da;
}
.Teal{
  color: #26a69a;
}
.Green{
  color: #66bb6a;
}
.LightGreen{
  color: #9ccc65;
}
.Lime{
  color: #d4e157;
}
.Yellow{
  color: #ffee58;
}
.Amber{
  color:  #ffca28;
}
.Orange{
  color: #ffa726;
}
.DeepOrange{
  color: #ff7043;
}
.Brown{
  color: #8d6e63;
}
.Grey{
  color: #bdbdbd;
}
.BlueGrey{
  color: #78909c;
}
.DeepOrangeN{
  color: #ff3d00;
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #03A9F4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #3f7992;
}

.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
  z-index: 10;
}

/* Bulk Data Layer List */
.mat-list-base .mat-list-option .mat-list-text{
  flex-direction: row !important;
  align-items: center !important;
}
.test .mat-grid-tile .mat-figure{
  right: unset !important; 
}

/*echarts*/
#chart-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}



html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
